// Layout
h1,
h2,
h3,
h4,
h5,
h6 {
    a {
        color: $light-blue;
    }
}
.tm-medium-text {
    p,
    span {
        font-size: 1.2em;
        line-height: 1.4em;
    }
}
.tm-main {
    background: $white;
}
.tm-shadow {
    box-shadow: 0 0px 8px rgba(0, 0, 0, 0.1);
}
.tm-image-shadow {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}
.tm-image-shadow {
    box-shadow: 0 2px 4px rgba(0,0,0,0.4);
}
.tm-thick-centered {
    border: 3px solid $light-gray;
    max-width: 330px;
    margin: 0 auto;
}
.tm-thin-fullwidth {
    border: 1px solid $light-gray;
    width: 100%;
}
.tm-cover {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}
.tm-text-shadow {
    text-shadow: 0px 1px 3px $black;
}
.tm-image-circle {
    border-radius: 50%;
}
.tm-right {
    float: right;
}
.tm-bodycopy-size {
    font-size: 16px;
}
.tm-wolf {
    background: url("/assets/img/franu-wolf.svg");
    height: 90%;
    width: 90%;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    opacity: 0.4;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
}
.tm-control-wrapper {
    background: $white;
    width: 100%;
    padding: 0;
    @media (min-width: 960px) {
        max-width: 980px;
        padding: 0 25px;
        margin: 0 auto;
        box-sizing: border-box;
    }
    @media (min-width: 1219px) {
        max-width: 1200px;
        padding: 0 35px;
        margin: 0 auto;
        box-sizing: border-box;
    }
}
.tm-list-nav {
    li {
        &:first-child {
            padding-left: 0;
        }
        a {
            color: $cyan;
            &:hover,
            &:focus,
            &:active {
                color: darken($cyan, 5%);
            }
        }
    }
}
.tm-link-more {
    line-height: 26px;
    &:before {
        content: none;
    }
    &:hover {
        text-decoration: none;
        &:after {
            padding-left: 12px;
        }
    }
    &:after {
        font-family: FontAwesome;
        font-size: 16px;
        content: "\f105";
        font-style: normal;
        padding-left: 8px;
    }
}
.tm-text-links {
    list-style: none;
    padding: 0;
    li {
        display: inline;
        margin: 0 5px;
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }
}
// Branding
.tm-brand {
    display: inline-table;
    @media (min-width: 768px) and (max-width: 959px) {
        margin: 0 auto;
    }
    img {
        width: 180px;
        @media screen and (min-width: 960px) {
            width: 240px;
        }
        @media screen and (min-width: 1024) {
            width: 270px;
        }
    }
}

// Banner
.tm-banner-image {
    position: relative;
    max-height: 500px;
    img {
        max-height: 460px;
    }
    h1 {
        text-shadow: 0 0px 8px rgba(0, 0, 0, 0.5);
        letter-spacing: 0.5px;
    }
    &.tm-banner-imagePage {
        padding-top: 29%;
    }
    &.tm-banner-homePage {
        padding-top: 42%;
    }
}

// Navigation
.tm-tertiary-nav-container {
    background: $dark-blue;
}
.tm-primary-nav-container {
    background: $blue;
    position: relative;
    .uk-container {
        padding-top: 8px;
        padding-bottom: 8px;
    }
}
.tm-mobile-nav-container {
    width: 100%;
}

.tm-flex-fit {
    font-size: 0;
    display: flex;
    &.tm-flex-fit-desktop {
        display: block;
        @media screen and (min-width: 960px) {
            display: flex;
        }
    }
    > li {
        flex-grow: 1;
    }
}
.tm-mobile-subnav {
    border-top: 1px solid #d9d9d9;
    > li {
        a {
            font-family: $headings-font;
            letter-spacing: 1px;
            color: $blue;
            font-size: 0.875em;
            padding: 15px 25px 15px 35px;
            background: $smoke;
            transition: background 0.3s ease-in;
            &:hover,
            &:focus,
            &:active {
                background: $yellow;
                color: $white;
            }
        }
    }
}
.tm-subnav {
    display: block;
    -ms-overflow-style: none;
    overflow: auto;
    /* overflow: scroll; */
    background: $light-gray;
    /* overflow-x: scroll; */
    /* -ms-overflow-style: -ms-autohiding-scrollbar; */
    ul {
        position: relative;
        margin: 4px auto;
        white-space: nowrap;
        width: 100%;
        text-align: center;
        height: 100%;
        li {
            display: inline-block;
            float: none;
            &.uk-active {
                a {
                    text-shadow: 1px 1px 0px $white;
                    &.tm-giving-button {
                        text-shadow: none;
                    }
                }
            }
            a {
                color: $text-primary-color;
                font-family: $body-font;
                font-size: 0.825em;
            }
        }
    }
}

// Hamburger
#tm-nav-icon {
    width: 26px;
    height: 20px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: $white;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
        &:nth-child(1) {
            top: 0px;
        }
        &:nth-child(2) {
            top: 8px;
        }
        &:nth-child(3) {
            top: 8px;
        }
        &:nth-child(4) {
            top: 16px;
        }
    }
    &.open {
        span {
            &:nth-child(1) {
                top: 8px;
                width: 0%;
                left: 50%;
            }
            &:nth-child(2) {
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
            }
            &:nth-child(3) {
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
                transform: rotate(-45deg);
            }
            &:nth-child(4) {
                top: 8px;
                width: 0%;
                left: 50%;
            }
        }
    }
}
// Banner
.tm-hero {
    background-size: cover;
    padding-bottom: 58.75%;
    background-repeat: no-repeat;
}

// Typography
.tm-intro {
    font-family: $headings-font;
    letter-spacing: 1px;
    font-size: $intro-font-size;
    line-height: $intro-line-height;
}
.tm-subtitle {
    font-family: $headings-font;
    line-height: 1.4em;
}

// Forms
.tm-combined-search {
    display: flex;
    margin: 0 auto;
    .tm-combined-search-input-wrapper {
        display: inline-block;
        position: relative;
        width: 100%;
        .tm-combined-search-input {
            width: 100%;
            padding: 10px 40px 10px 20px !important;
            height: 40px;
            border-radius: 4px;
        }
        .tm-combined-search-button {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 14px;
        }
    }
}
.tm-checkbox-hidden {
    display: none;
}
// Footer Nav
.tm-footer-bar {
    background: $blue;
    height: 138px;
    @media (min-width: 959px) {
        height: 78px;
    }
    .tm-footer-bar--buttons {
        list-style: none;
        margin: 16px 0;
        padding: 0;
        li {
            display: inline-block;
            padding-left: 10px;
            &:first-child {
                padding-left: 0;
            }
            a {
                &:after {
                    content: none;
                    padding: 0;
                }
                &.uk-button {
                    padding: 12px 56px 10px;
                }
            }
        }
    }
    .tm-footer-bar--social {
        list-style: none;
        margin: 16px 0;
        padding: 0;
        li {
            display: inline-block;
            padding-right: 10px;
            &:last-child {
                padding-right: 0;
            }
            a {
                font-size: 2em;
                color: $white;
                line-height: 1.5em;
                &:hover,
                &:focus,
                &:active {
                    color: $white;
                }
            }
        }
    }
}
.tm-footer-crest-container {
    width: 138px;
    height: 138px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}
.tm-footer-crest {
    width: 138px;
    padding: 2px 7px 12px 7px;
    background: $blue;
    border-radius: 100px;
    margin: -36px auto 0;
    display: block;
}
.tm-footer-sitemap {
    > li {
        margin-top: 30px;
        @media screen and (min-width: 959px) {
            margin-top: 60px;
        }
        > a {
            font-family: $headings-font;
            letter-spacing: 1px;
            font-size: 18px;
            display: inline-block;
            &:after {
                display: block;
                content: "";
                border-bottom: solid 2px $white;
                transform: scaleX(0);
                transition: transform 250ms ease-in-out;
                transform-origin: 0% 50%;
            }
            &:hover {
                &:after {
                    transform: scaleX(1);
                }
            }
        }
        a {
            color: $white;
        }
    }
    .tm-footer-sitemap--buttons {
        margin-top: 10px;
        @media (min-width: 959px) {
            margin-top: 60px;
        }
        li {
            margin-left: 10px;
            &:first-child {
                margin-left: 0;
            }
            a {
                font-size: 18px;
                width: 100%;
                &:after {
                    content: none;
                    padding: 0;
                }
            }
        }
    }
    .tm-footer-sitemap--list {
        padding-top: 8px;
        > li {
            line-height: 2;
            > a {
                font-weight: 100;
                font-size: 14px;
                padding: 0;
            }
        }
    }
}

.tm-footer-nav {
    text-align: center;
    margin-bottom: 6px;
    > li {
        display: inline-block;
        padding: 0 10px;
        > a {
            font-family: $headings-font;
            letter-spacing: 1px;
            color: $white;
            font-size: 14px;
        }
    }
}

// Call To Action
.tm-call-to-action {
    padding: 40px;
    min-height: 288px;
    form {
        margin-top: 20px;
    }
    p {
        font-weight: 500;
        letter-spacing: 1px;
        @media screen and (min-width: 768px) {
            font-weight: 400;
        }
    }
}
.tm-call-to-action-underline {
    border-bottom: 6px solid $yellow;
}
.tm-multigrid-cta {
    background: $blue;
    min-height: 400px;
    flex-direction: column;
    @media screen and (min-width: 768px) {
        flex-direction: row;
    }
    .tm-multigrid-cta--content {
        padding: 40px;
    }
}
.tm-multigrid {
    height: 100%;
}
.tm-multigrid-1 {
    flex: 1 percentage(1/2);
    min-height: 400px;
}
.tm-multigrid-2 {
    flex: 1 percentage(1/2);
}
.tm-multigrid-element {
    width: 100%;
    flex-direction: column;
}
.tm-multigrid-content {
    flex: 1 0;
}

// Link List
.tm-linkList {
    p {
        margin-top: 0;
        font-size: 0.875em;
        line-height: 1.5em;
    }
}
.tm-linkblock {
    .tm-linkblock--effect figcaption::before,
    .tm-linkblock--effect p,
    .tm-linkblock--effect a {
        opacity: 0;
        -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
        transition: opacity 0.35s, transform 0.35s;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
}
// Link block
.tm-linkblock--container {
    display: block;
    position: relative;
    color: $white;
    text-align: center;
    p {
        font-size: 0.75em;
        line-height: 1.75em;
        margin: 5px 0;
    }
    a {
        color: $yellow;
        &:hover {
            color: darken($yellow, 5%);
        }
    }
}
.tm-linkblock--title-container {
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;
}
.tm-linkblock--title {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.tm-linkblock--title,
.tm-linkblock--hidden {
    font-family: $card-heading-font, sans-serif;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1.5px;
    color: $white;
    font-size: 1.3125em;
}
.tm-linkblock--content {
    position: absolute;
    padding: 30px;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
    @media (min-width: 768px) {
        padding: 40px;
    }
}
.tm-linkblock--effect {
    position: relative;
    figcaption {
        position: relative;
        display: block;
        //top: 0;
        //left: 0;
        //right: 0;
        //padding: 0;
        color: $white;
        backface-visibility: hidden;
        &:before,
        &:after {
            pointer-events: none;
        }
        &:before {
            position: absolute;
            top: 20px;
            right: 20px;
            bottom: 20px;
            left: 20px;
            border: 1px solid $white;
            content: "";
        }
    }
    .tm-linkblock--title {
        transition: top 0.05s linear;
        padding: 30px;
        @media (min-width: 768px) {
            padding: 40px;
        }
    }
    .tm-linkblock--hidden {
        opacity: 0;
        display: block;
        margin: 0;
    }
    &:hover {
        .tm-linkblock--title-container {
            display: none;
        }
        figcaption {
            background-color: rgba(58, 52, 42, 0);
            &:before {
                opacity: 1;
                transform: scale(1);
            }
        }
        p,
        a {
            opacity: 1;
            transform: scale(1);
        }
        .tm-linkblock--title {
            position: absolute;
            top: 45px;
            left: 0;
            right: 0;
            margin: auto;
        }
        .tm-linkblock--hidden {
            opacity: 1;
        }
    }
}
// Collage Link blocks
.tm-collage {
    .tm-linkblock--effect p,
    .tm-linkblock--effect a {
        opacity: 0;
        -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
        transition: opacity 0.35s, transform 0.35s;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    .tm-linkblock--effect {
        &:hover {
            p,
            a {
                opacity: 1;
                transform: scale(1);
            }
        }
    }
}

.caption__media {
    display: block;
    min-width: 100%;
    max-width: 100%;
}

// Board
.tm-board-list {
    .uk-panel-teaser {
        border-bottom: 4px solid $yellow;
    }
    .uk-panel-title {
        a {
            color: $blue;
            &:hover {
                color: darken($blue, 5%);
            }
        }
    }
    .uk-position-bottom {
        padding: 20px 20px 10px;
    }
    p {
        font-size: 0.875em;
        line-height: 1.6em;
    }
}

// News
.tm-news-list {
    .uk-panel {
        padding-bottom: 44px;
    }
    .uk-panel-teaser {
        border-bottom: 4px solid $yellow;
    }
    .uk-panel-title {
        a {
            color: $blue;
            &:hover {
                color: darken($blue, 5%);
            }
        }
    }
    .uk-position-bottom {
        padding: 20px 20px 10px;
    }
    p {
        font-size: 0.875em;
        line-height: 1.6em;
    }
    .tm-card-list {
        list-style: none;
        padding: 0;
        margin: 5px 0 0;
        .tm-post-date {
            font-family: $headings-font;
            font-size: 0.625em;
            line-height: 1.3;
            color: $news-poste-date;
        }
        .tm-read-more {
            line-height: 1.3;
            a {
                color: $cyan;
                font-size: 0.8em;
                &:hover {
                    color: darken($cyan, 5%);
                }
            }
        }
    }
    hr {
        border-top: 1px solid $light-gray;
        margin: 15px 0 0;
    }
}

// News
.tm-event-list {
    .uk-panel {
        padding-bottom: 44px;
    }
    .uk-panel-teaser {
        border-bottom: 4px solid $yellow;
    }
    .uk-panel-title {
        a {
            color: $blue;
            &:hover {
                color: darken($blue, 5%);
            }
        }
    }
    .uk-position-bottom {
        padding: 20px 20px 10px;
    }
    p {
        font-size: 0.875em;
        line-height: 1.6em;
    }
    .tm-event-date {
        font-family: $headings-font;
        font-size: 0.825em;
        line-height: 1.3;
        color: $light-blue;
        margin-bottom: 5px;
    }
    .tm-card-list {
        list-style: none;
        padding: 0;
        margin: 5px 0 0;
        
        .tm-read-more {
            line-height: 1.3;
            a {
                color: $cyan;
                font-size: 0.8em;
                &:hover {
                    color: darken($cyan, 5%);
                }
            }
        }
    }
    hr {
        border-top: 1px solid $light-gray;
        margin: 15px 0 0;
    }
}

// Breadcrumbs
.tm-breadcrumbs-dropdown {
    position: relative;
}

// Courses
.tm-course-listing {
    .tm-course-listing--details {
        color: $gray;
        &:hover {
            color: darken($gray, 5%);
        }
    }
    .tm-course-listing--school {
    }
}
.tm-dropdown-container {
    margin-left: 10px;
}
.tm-filter-dropdown {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
}
// Majors
.tm-major-list {
    li {
        margin-top: 60px;
        &:first-child {
            margin-top: 0;
        }
        .uk-list-line {
            li {
                &:last-child {
                    border-bottom: 1px solid #ddd;
                }
            }
        }
    }
    h3 {
        font-family: $card-heading-font, sans-serif;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 1px;
        a {
            color: $gray;
            &:hover,
            &:focus,
            &:active {
                color: darken($gray, 5%);
            }
        }
    }
    h5 {
        /* font-family: $body-font; */
        font-size: 16px;
        font-weight: 400;
        font-style: italic;
        /* color: #999; */
    }
    .tm-major-list--track {
        color: $cyan;
        p {
            font-size: 0.8em;
            line-height: 1.6em;
        }
        &:hover,
        &:focus,
        &:active {
            p {
                color: darken($cyan, 5%);
            }
        }
    }
}

// Important Dates
.tm-important-dates {
    h6 {
        font-family: $card-heading-font, sans-serif;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 1px;
        font-size: 1.6em;
        line-height: 1.2em;
    }
    p {
        color: $yellow;
        line-height: 1.6em;
        font-size: 0.8em;
        font-weight: 500;
    }
    .tm-important-dates--description {
        color: $gray;
    }
}
// Cards
.tm-card-date {
    border: 1px solid $light-blue;
    float: left;
    height: 46px;
    width: 46px;
    min-width: 46px;
    margin-right: 10px;
    p {
        color: $light-blue;
        text-align: center;
    }
    .tm-card-date--month {
        font-family: $headings-font;
        font-size: 0.8em;
        line-height: 1.4em;
    }
    .tm-card-date--day {
        font-family: $card-heading-font, sans-serif;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 1px;
        font-size: 1.5em;
        line-height: 0.95em;
    }
}
.uk-slidenav-position .uk-slidenav {
    display: block;
}
#js-footer-accordion {
    .uk-accordion-title {
        font-family: $card-heading-font, sans-serif;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 2px;
        margin: 0 auto;
        position: relative;
        display: block;
        text-align: center;
        padding: 20px 0;
        color: white;
        font-size: 1.4em;
        &:before {
            content: none;
        }
        &.uk-active {
            &:after {
                font-family: FontAwesome;
                content: "\f106";
                padding-left: 10px;
            }
        }
        &:after {
            font-family: FontAwesome;
            content: "\f107";
            padding-left: 10px;
        }
    }
}

// Pagination
.tm-pagination {
    text-align: center;
    padding-left: 0;
    .tm-pagination__item {
        display: inline-block;
        margin-right: 16px;
        margin-left: 16px;
        a {
            position: relative;
            min-width: 8em;
            text-decoration: none;
        }
    }
    .tm-pagination__button--disabled {
        color: lighten($gray, 10%);
        &:hover,
        &:focus,
        &:active {
            color: lighten($gray, 10%);
        }
    }
}

// Offices and Services
.tm-offices-list {
    h2 {
        a {
            color: $dark-blue;
            &:hover,
            &:focus,
            &:active {
                color: $yellow;
            }
        }
    }
    .tm-offices-list--contain {
        position: relative;
        .overlay {
            opacity: 0;
            background: rgba(0, 0, 0, 0.2);
            position: absolute;
            width: 100%;
            padding-bottom: 33.333%;
            transition: opacity 0.25s ease-in-out;
        }
        &:hover {
            .overlay {
                opacity: 1;
            }
        }
        a {
            display: block;
        }
        .tm-offices-list--title {
            position: absolute;
            bottom: 4px;
            margin: 0;
            left: 10px;
            color: $white;
            text-shadow: 2px 1px 1px rgba(0, 0, 0, 0.5);
            letter-spacing: 1px;
            line-height: 1.2em;
            a {
                color: $white;
            }
        }
    }
    .tm-offices-list--contact {
        p {
            font-size: 0.8em;
            line-height: 1.8em;
            &:last-child {
                margin-top: 0;
            }
        }
    }
    .tm-offices-list--children {
        li {
            padding: 0 10px;
            border-top: 1px solid #ddd;
            &:first-child {
                border-top: 0;
            }
            a {
                font-size: 0.8em;
                line-height: 1.6em;
            }
        }
    }
}

// Directory
#tm-directory {
    .tm-directory-title {
        font-weight: 600;
        color: $light-blue;
        a {
            color: $light-blue;
            &:hover,
            &:focus,
            &:active {
                color: $cyan;
            }
        }
    }
    .tm-directory-position {
        font-family: $serif-font;
        color: darken($light-gray, 20%);
        font-size: 80%;
        display: inline;
    }
}
// History
.tm-history {
    position: relative;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    &:before {
        background-color: rgba(0, 0, 0, 0.1);
        content: "";
        display: block;
        width: 2px;
        height: 100%;
        position: absolute;
        z-index: 0;
        top: 0;
        left: calc(50% - 2px);
        @media (max-width: 767px) {
            display: none;
        }
    }
}
.tm-history-year {
    margin-top: 0;
    padding-right: 0;
    @media (min-width: 768px) {
        margin-top: 64px;
        padding-right: 20px;
    }
    @media (min-width: 1219px) {
        margin-top: 64px;
        padding-right: 15px;
    }
    &.tm-history-year--right {
        margin-top: 0;
        padding-right: 0;
        padding-left: 0;
        @media (min-width: 768px) {
            margin-top: 64px * 3;
            padding-right: 0;
            padding-left: 17px;
        }
        @media (min-width: 1219px) {
            margin-top: 64px * 6;
            padding-left: 12px;
            padding-right: 0;
        }
        .tm-history-year__image {
            &:after {
                @media (min-width: 767px) {
                    left: -(23px + 15px/2 + 8px);
                    right: auto;
                }
            }
            &:before {
                @media (min-width: 767px) {
                    left: -(23px + 15px/2);
                    right: auto;
                }
            }
        }
        .tm-history-year__text {
            text-align: left;
        }
    }
    .tm-history-year__image {
        position: relative;
        &:after {
            content: none;
            @media (min-width: 768px) {
                content: " ";
                background-color: $yellow;
                position: absolute;
                top: 50%;
                right: -(23px + 15px/2 + 8px);
                height: 16px;
                width: 16px;
                border-radius: 16px;
                margin-top: -8px;
            }
        }
        &:before {
            content: none;
            @media (min-width: 768px) {
                content: " ";
                height: 2px;
                background-color: rgba(0, 0, 0, 0.1);
                width: (23px + 15px/2);
                position: absolute;
                top: 50%;
                right: 0;
                margin-top: -1px;
                margin-right: -(23px + 15px/2);
            }
        }
    }
    .tm-history-year__text {
        text-align: left;
        @media (min-width: 767px) {
            text-align: right;
        }
        .tm-history-date {
            color: lighten($gray, 10%);
            font-family: $body-font;
            font-weight: 400;
        }
    }
}
.tm-collage {
    .uk-collage-right-top {
        padding: 0px;
        @media (min-width: 480px) {
            padding-top: 60px;
        }
        @media (min-width: 1024px) {
            padding-right: 80px;
            padding-top: 90px;
        }
    }
    .uk-collage-right-80 {
        padding: 0;
        @media (min-width: 1024px) {
            padding-right: 80px;
        }
    }
    .uk-collage-right-20 {
        padding: 0;
        @media (min-width: 1024px) {
            padding-right: 20px;
        }
    }
    .uk-collage-left-60 {
        padding: 0;
        @media (min-width: 1024px) {
            padding-left: 60px;
        }
    }
    .uk-collage-left-40 {
        padding: 0;
        @media (min-width: 1024px) {
            padding-left: 40px;
        }
    }
}
// Search
.tm-site-search {
    position: relative;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    @at-root #{&}--close {
        display: block;
        position: absolute;
        height: 50px;
        width: 50px;
        top: 50px;
        right: 50px;
    }
    @at-root #{&}--x {
        &:before,
        &:after {
            content: "";
            top: 24px;
            left: 0;
            right: 0;
            margin: 0 auto;
            display: block;
            width: 2.5rem;
            height: 1px;
            background: #fff;
            position: absolute;
            z-index: 99;
            -webkit-transition: -webkit-transform 0.4s ease-in-out;
            transition: -webkit-transform 0.4s ease-in-out;
            transition: transform 0.4s ease-in-out;
            transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }
        &:after {
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }
        &:before {
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
    /* @at-root #{&}--input {
        width: 100%;
        background-color: transparent;
        border: none;
        border-bottom: 2px solid $yellow;
        height: 50px;
        margin-bottom: 50px;
        color: $blue;
        font-size: 1.3em;
        text-align: center;
        .uk-contrast & {
            color: $white;
        }
        &:focus {
            outline-width: 0;
            outline-style: none;
        }
    } */
}
.js-search-filter {
    .uk-subnav {
        .uk-active {
            background: $yellow;
        }
        .tm-subnav-title {
            padding: 3px 9px;
        }
    }
}
#js-load {
    display: none;
}
.tm-combined-search {
    display: flex;
    max-width: 600px;
    margin: 0 auto;
    form {
        width: 100%;
    }
    .tm-combined-search-input-wrapper {
        display: inline-block;
        position: relative;
        width: 100%;
        .tm-combined-search-input {
            width: 100%;
            padding: 10px 40px 10px 20px !important;
            height: 40px;
        }
        .tm-combined-search-button {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 14px;
            color: $gray;
            &:hover,
            &:focus,
            &:active {
                color: $yellow;
            }
        }
    }
}

.tm-filters {
    label {
        font-size: 0.8em;
    }
}
.tm-contain-iframe {
    -webkit-overflow-scrolling: touch;
    overflow-y: hidden;
    iframe {
        border: 0;
    }
}
.tm-carousel-video-controls {
    z-index: 100000;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.tm-video-wrapper {
    background: $black;
}
.video-embed {
    position: relative;
    @at-root #{&}[data-has-placeholder="true"] {
        .video-embed__video {
            display: none;
        }
    }
    &:hover {
        a {
            &.close-video {
                opacity: 1;
            }
        }
    }
    .video-embed__placeholder {
    }
    a {
        &.close-video {
            opacity: 0;
            transition: opacity 0.3s ease-in;
            position: absolute;
            right: 40px;
            top: 20px;
            z-index: 100;
            font-size: 1.8em;
            color: $white;
            &:hover {
                background-color: rgb(0, 173, 239);
            }
            .fa-times {
                color: $white;
                display: block;
                margin: 0 auto;
                text-align: center;
                font-size: 18px;
            }
        }
    }
}
.content-overlay {
    position: absolute;
    text-align: center;
    max-width: 1000px;
    width: calc(100% - 60px);
    padding-left: 20px;
    padding-right: 20px;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 0;
    display: block;
    top: 50%;
    transform: translateY(-50%);
    .uk-slideshow & {
        display: flex;
        top: 30%;
        transform: translateY(-25%);
        @media (min-width: 768px) {
            top: 50%;
            transform: translateY(-50%);
            width: calc(100% - 120px);
            padding-left: 60px;
            padding-right: 60px;
            bottom: auto;
            display: block;
        }
    }
    @media (min-width: 768px) {
        top: 50%;
        transform: translateY(-50%);
        width: calc(100% - 120px);
        padding-left: 60px;
        padding-right: 60px;
        bottom: auto;
        display: block;
    }
    img {
        width: auto;
        max-height: 100px;
        @media (min-width: 768px) {
            max-height: 120px;
        }
        @media (min-width: 1020px) {
            max-height: 200px;
        }
    }
    a {
        color: $white;
        text-decoration: none;
    }
    h2,
    h3,
    h4,
    ,
    p {
        color: $white;
        text-shadow: 0px 1px 3px $black;
    }
    .js-endingCopy {
        display: none;
    }
    .content-overlay-play {
        a {
            font-family: $card-heading-font;
            font-size: 2.4em;
            letter-spacing: 1px;
            font-weight: normal;
            line-height: 1;
            i {
                border: 3px solid $white;
                border-radius: 100px;
                padding: 6px 5px 6px 10px;
                font-size: 0.6em;
                margin-right: 8px;
            }
        }
    }
    .content-overlay-replay {
        margin-top: 6px;
        a {
            font-size: 0.8em;
            letter-spacing: 1px;
            font-weight: normal;
        }
    }
    .tm-center-overlay {
        vertical-align: middle;
        display: flex;
        align-items: center;
        @media (min-width: 768px) {
            display: block;
        }
    }
}
.tm-responsive-media {
    padding-bottom: 56.25%;
    @media screen and (min-width: 768px) {
        padding-bottom: 56.25%;
    }
    .uk-slideshow & {
        padding-bottom: 100%;
        @media screen and (min-width: 960px) {
            padding-bottom: 66.667%;
        }
        @media screen and (min-width: 1024px) {
            padding-bottom: 40.23%;
        }
    }
    &.tm-responsive-media-banner {
        padding-bottom: 43.25%;
    }
}
.tm-responsive-media__item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @at-root #{&}--gutter {
        box-sizing: border-box;
        padding: 40px;
    }
    @at-root #{&}__inner {
        @at-root #{&}--vertically-center {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

// FAQ
.tm-faq {
    .uk-accordion-title {
        border-top: 1px solid $light-gray;
        padding: 15px 0;
        &:before {
            font-size: 0.8em;
        }
    }
    .uk-accordion-content {
        padding-left: 28px;
    }
}

// Table
.tm-resource-table {
    tr {
        td:last-child {
            text-align: left;
            @media (min-width: 768px) {
                text-align: right;
            }
        }
    }
}
.tm-responsive-table {
    th,
    td {
        display: block;
        @media (min-width: 768px) {
            display: table-cell;
        }
    }
    td {
        padding: 4px 0;
        @media (min-width: 768px) {
            padding: 4px 8px;
        }
    }
    tbody {
        tr {
            display: block;
            border-bottom: 1px solid $light-gray;
            padding: 10px 0;
            @media (min-width: 768px) {
                display: table-row;
                border: 0;
                padding: 0;
            }
        }
    }
}
.tm-table-head {
    font-weight: bold;
}
table {
    @extend .uk-table;
}
.tm-resource-tags {
    margin-top: 10px;
}
.tm-program-contacts {
    font-size: 0.8em;
    line-height: 1.8;
}
.tm-control-nav {
    li {
        margin-left: 4px;
        &:first-child {
            margin-left: 0;
        }
        a {
            padding: 4px 6px;
            border: 1px solid $light-gray;
            color: $gray;
            &:hover {
                background: $blue;
                color: $white;
            }
            &.uk-active {
                background: $yellow;
                color: $blue;
            }
        }
    }
}
.tm-giving-circle {
    font-size: 1.2em;
}
.tm-rockit {
    color: $white;
    &:hover {
        color: $white;
        text-decoration: underline;
    }
}
.tm-tracks {
    padding: 20px 0;
    h6 {
        left: 0;
        right: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}
.tm-semester--groupTitle {
    font-size: 80%;
    color: lighten($gray, 10%);
    font-family: $headings-font;
}
.tm-spotlight-image-container {
    display: block;
    position: relative;
    width: 100%;
    .preserve3d & {
        transition: 0.5s;
        transform-style: preserve-3d;
        &.flip {
            &:hover {
                transform: rotateY(180deg);
            }
        }
    }
    .no-preserve3d & {
        &.flip {
            height: 100%;
            &:hover {
                .tm-spotlight-image-front {
                    display: none;
                }
                .tm-spotlight-image-back {
                    display: block;
                }
            }
        }
    }
    .tm-spotlight-image-front {
        overflow: hidden;
        padding-bottom: 100%;
        display: block;
        width: 100%;
        img {
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            border-radius: 100%;
            -webkit-mask-image: -webkit-radial-gradient(white, black);
        }
    }
    .tm-spotlight-image-back {
        overflow: hidden;
        border-radius: 100%;
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        height: 100%;
        display: block;
        width: 100%;
        background: $dark-blue;
        .preserve3d & {
            position: absolute;
            top: 0;
            left: 0;
            transform: rotateY(180deg);
        }
        .no-preserve3d & {
            display: none;
        }
        .tm-spotlight-image-back--content {
            padding: 0 2rem;
            p {
                line-height: 1.5rem;
                color: $white;
                opacity: 0.7;
                &.mobile-label {
                    color: $yellow;
                    opacity: 1;
                }
            }
        }
    }
    .tm-spotlight-image-front,
    .tm-spotlight-image-back {
        backface-visibility: hidden;
        .preserve3d & {
            transition: 0.6s;
            transform-style: preserve-3d;
        }
    }
    /* front pane, placed above back */
    .tm-spotlight-image-front {
        z-index: 2;
        /* for firefox 31 */
        .preserve3d & {
            transform: rotateY(0deg);
        }
    }
}
.spotlight-modal-container {
    border-bottom: 10px solid $yellow;
    .spotlight-modal {
        padding: 3rem;
        .spotlight-modal-content {
            .spotlight-modal-title {
                color: $yellow;
            }
        }
    }
}
.modal-open {
    overflow: hidden;
}

.modal-wrapper {
    position: fixed; 
    height: 100%;
    width: 100%;
    z-index: 100;
    background: rgba(0,0,0,0.3);
    .modal-container {
        top: 50%;
        transform: translatey(-50%);
        position: absolute;
        width: 70%;
        left: 0;
        right: 0;
        margin: auto;
        // background: #efb41d;
        background: $yellow;
        padding: 50px;
        color: white;
    }
}
.ivy-main{
    z-index: 99;
    position: absolute !important;

}

.uk-form label:not(.uk-form-label) {
    width: 100%;
    display: block;
    margin-right: 5px;
}
input[type="checkbox"], input[type="radio"] {
    margin-right: 5px;
}
.uk-form-help-block {
    font-size: $text-small-font-size;
    margin-top: 2px;
    line-height: 1.6;
}
.freeform-errors {
    color: red;
    list-style: none;
    font-size: 0.8rem;
    padding-left: 0;
    margin-top: 5px;
}
.uk-required::after {
    content: "*";
    color: red;
    margin-left: 2px;
}

/* Apply styles to any element with a class that starts with freeform-col- */
[class*="freeform-col-"] {
    float: none !important;
    padding: 0 0 0 25px !important;
}
@media (min-width: 1220px) {
    [class*="freeform-col-"] {
        float: none !important;
        padding: 0 0 0 35px !important;
    }
}
/* Increase specificity to override more specific class selectors */
@media only screen and (min-width: 801px) {
    .freeform-col-1 {
        width: 8.333% !important;
    }
    .freeform-col-2 {
        width: 16.666% !important;
    }
    .freeform-col-3 {
        width: 25% !important;
    }
    .freeform-col-4 {
        width: 33.333% !important;
    }
    .freeform-col-5 {
        width: 41.666% !important;
    }
    .freeform-col-6 {
        width: 50% !important;
    }
    .freeform-col-7 {
        width: 58.333% !important;
    }
    .freeform-col-8 {
        width: 66.666% !important;
    }
    .freeform-col-9 {
        width: 75% !important;
    }
    .freeform-col-10 {
        width: 83.333% !important;
    }
    .freeform-col-11 {
        width: 91.666% !important;
    }
    .freeform-col-12 {
        width: 100% !important;
    }
}