@mixin hook-base-body() {
    @media screen and (min-width: 768px) {
        font: $base-body-font-weight 16px unquote("/") 28px $base-body-font-family;
    }
}
@mixin hook-block-misc() {
    .uk-block {
        &:last-child {
            padding-bottom: 70px;
            &.uk-block-large {
                padding-bottom: 120px;
            }
            &.uk-block-small {
                padding-bottom: 60px;
            }
        }
        .uk-block {
            &:first-child {
                padding-top: 0;
            }
        }
    }
}
@mixin hook-navbar() {
    width: 100%;
    height: 100%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    position: relative;
    @media (min-width: 768px) and (max-width: 959px) {
        display: block;
        &.tm-subnav {
            display: flex;
        }
    }
}
@mixin hook-navbar-nav() {
    letter-spacing: 1px;
}
@mixin hook-navbar-misc() {
    .uk-navbar-nav {
        li {
            a {
                &.tm-giving-button {
                    transition: color .25s ease-in-out;
                    color: $white;
                    padding: 0 10px;
                    border: 0;
                    &:after {
                        content: none;
                    }
                }
            }
        }
        .tm-primary-nav-container & {
            @media (min-width: 768px) and (max-width: 959px) {
                width: 100%;
                text-align: center;
                li {
                    float: none;
                    display: inline-block;
                }
            }
            li {
                &.uk-active {
                    > a {
                         border-bottom: 2px solid $yellow;
                    }
                }
                a {
                    transition: color .25s ease-in-out;
                    &.uk-button, &.uk-button-primary {
                        padding: 0px 14px;
                        min-height: 10px;
                        font-family: $card-heading-font;
                        letter-spacing: 1px;
                        font-size: 18px;
                    }
                    &.uk-button {
                        line-height: 1.5em;
                        &:hover, &:focus, &:active {
                            color: $white;
                        }
                        &:after {
                            content: none;
                            padding: 0;
                        }
                    }
                    &.uk-button-primary {
                        line-height: 1.6em;
                        &:hover, &:focus, &:active {
                            color: $white;
                        }
                    }
                }
            }
        }
        &#tertiary-nav {
            @media (min-width: 768px) and (max-width: 959px) {
                width: 100%;
                text-align: center;
                li {
                    float: none;
                    display: inline-block;
                }
            }
        }
        .tm-alternate-nav-container & {
            border-bottom: 1px solid $light-gray;
            margin: 0 auto;
            white-space: nowrap;
            overflow-x: scroll;
            font-size: 0;
            float: none;
            text-align: center;
            display: flex;
            width: 100%;
            height: 100%;
            li {
                border-left: 1px solid $light-gray;
                padding: 0;
                display: inline-block;
                float: none;
                font-size: 16px;
                width: 100%;
                margin: 0;
                &:first-child {
                    border-left: 0;
                }
                a {
                    color: $gray;
                    transition: color .25s ease-in-out;
                    text-align: center;
                    font-size: 0.75em;
                    line-height: 1.2em;
                    padding: 10px 15px;
                    text-align: center;
                    display: block;
                    &:hover, &:focus, &:active {
                        background: $teal;
                        color: $white;
                    }
                    &.uk-active {
                        background: $teal;
                        color: $white;
                        &:hover, &:focus, &:active {
                            background: darken($teal, 5%);
                            color: $white;
                        }
                    }
                }
            }
        }
    }

}
@mixin hook-nav-dropdown() {
    font-family: $headings-font;
    color: $blue;
}
@mixin hook-text-large() {
    font-family: $serif-font;
    strong {
        font-family: $headings-font;
    }
}
@mixin hook-text-misc() {
    .uk-text-small-paragraph {
        p {
            font-size: $text-small-font-size;
            line-height: $text-small-line-height;
        }
    }
    .uk-text-medium-paragraph {
        p {
            font-size: $text-medium-font-size;
            line-height: $text-medium-line-height;
        }
    }
    .uk-text-large-paragraph {
        p {
            font-size: $text-large-font-size;
            line-height: $text-large-line-height;
            font-weight: $text-large-font-weight;
        }
    }
}
@mixin hook-button() {
    border: 1px solid $white;
    font-family: $card-heading-font;
    font-size: 1.25em;
    letter-spacing: 1.5px;
    padding: 12px 38px 10px;
    text-shadow: none;
    transition: background-color 0.25s ease-in-out;
    &:after {
        font-family: FontAwesome;
        font-size: 0.875em;
        content: "\f105";
        font-style: normal;
        padding-left: 4px;
        transition: padding 0.4s; // Making dreams come true
    }
}
@mixin hook-panel-box() {
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.15);
    .tm-curriculum-slider & {
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.3);
        border-radius: $curriculum-border-radius;
        .uk-panel-teaser {
            margin-bottom: -20px;
            padding: 12px;
            background: $yellow;
            color: white;
            border-bottom-left-radius: $curriculum-border-radius;
            border-bottom-right-radius: $curriculum-border-radius;
        }
    }
    &.tm-panel-outline {
        border: 1px solid $light-gray;
        box-shadow: none;
    }
    &.uk-panel-box-thick {
        padding: 40px;
    }
}
@mixin hook-panel-title() {
    font-family: $card-heading-font;
}
@mixin hook-thumbnail-caption() {
    font-size: 0.8em;
    line-height: 1.4em;
    text-align: left;
}
@mixin hook-breadcrumb() {
    border-bottom: 1px solid $light-gray;
    padding: 3px 25px 1px 25px;
    margin: 0;
    height: 28px;
    @media (min-width: 960px) {
        padding: 10px 0 1px 0px;
    }
}

@mixin hook-breadcrumb-misc() {
    .uk-breadcrumb > li {
        color: $gray;
        > a {
            color: $gray;
        }
    }
}
@mixin hook-dropdown-misc() {
    .tm-primary-nav-container {
        .uk-dropdown {
            list-style: none;
            .uk-nav {
                box-shadow: 0px 1px 4px rgba(0,0,0,0.2);
            }
            li {
                background: $white;
                line-height: 1.4;
                font-size: 14px;
                border-bottom: 1px solid whitesmoke;
                &:last-child {
                    border-bottom: 0;
                }
                a {
                    display: block;
                    color: $gray;
                    padding: 6px 8px;
                    &:hover, &:active, &:focus {
                        background: $yellow;
                        color: $white;
                    }
                }
                &.uk-active {
                    a {
                        background: $yellow;
                        color: $white;
                    }
                }
            }
            /* mobile nav */
            .tm-mobile-nav {
                .uk-accordion-title {
                    padding: 0;
                    margin: 0;
                }
                li {
                    border-top: 1px solid $nav-border;
                    &:first-child {
                        border-top: 0;
                    }
                    a {
                        padding: 15px 25px;
                        transition: background 0.3s ease-in;
                        &.uk-active {
                            background: $yellow;
                            color: $white;
                        }
                        &.tm-giving-button {
                            background: $giving-green;
                            color: $white;
                            &:hover, &:active, &:focus {
                                background: darken($giving-green, 5%);
                            }
                        }
                    }
                    .tm-mobile-nav--buttons {
                        background: $light-gray;
                        padding: 3px 0;
                        li {
                            text-align: center;
                            border-left: 2px solid $light-gray;
                            border-top: 0;
                            &:first-child {
                                border-left: 0;
                            }
                            a {
                                font-family: $card-heading-font, sans-serif;
                                font-style: normal;
                                font-weight: 400;
                                letter-spacing: 1px;
                                color: $white;
                                background: $light-blue;
                                font-size: 18px;
                                padding: 20px 0;
                                transition: background 0.3s ease-in;
                                &:hover, &:focus, &:active {
                                    background: darken($light-blue, 5%);
                                }
                                img {
                                    width: 26px;
                                    display: block;
                                    margin: 0 auto 5px;
                                }
                            }
                        }
                    }
                    &.tm-mobile-nav--search {
                        padding: 10px 0;
                    }
                }
            }
        }
    }
    .tm-breadcrumbs-dropdown {
        .uk-dropdown-small {
            border-radius: 4px;
            border: $light-gray;
            .uk-nav-dropdown {
                &:before {
                    content:"";
                    position: absolute;
                    right: 0px;
                    left: 0;
                    margin: auto;
                    top: -10px;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 10px 10px 10px;
                    border-color: transparent transparent $white transparent;
                    z-index:9999;
                }
                &:after {
                    content:"";
                    position: absolute;
                    right: 0px;
                    left: 0;
                    margin: auto;
                    top: -12px;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 10px 12px 10px;
                    border-color: transparent transparent $light-gray transparent;
                    z-index:9998;
                }
                > li {
                    > a {
                        font-family: $body-font;
                        color: $gray;
                        padding: 4px 10px 4px 22px;
                        border-top: 1px solid $light-gray;
                        font-size: 0.8em;
                        line-height: 1.6em;
                        &:hover, &:focus, &:active {
                            background: $gray;
                            color: $white;
                        }
                        &.uk-active {
                            background: $yellow;
                            color: $white;
                        }
                        &.tm-dropdown-parent {
                            padding: 4px 10px;
                        }
                    }
                    &:first-child {
                        > a {
                            border-top-right-radius: 4px;
                            border-top-left-radius: 4px;
                            border-top: 0;
                        }
                    }
                    &:last-child {
                        > a {
                            border-bottom-right-radius: 4px;
                            border-bottom-left-radius: 4px;
                        }
                    }
                }
            }
        }
    }
}
@mixin hook-slideshow-misc() {
    .uk-slideshow img {
        width: 100%;
    }
    .uk-slider-container {
        .tm-curriculum-slider & {
            overflow: visible;
        }
    }
}
@mixin hook-slider-misc() {
    .uk-slider {
        .tm-curriculum-slider & {
            li {
                &:first-child {
                    padding-left: 0;
                }
                &:last-child {
                    padding-right: 25px;
                }
            }
        }
        &.js-view-all {
            min-width: 100% !important;
            transform: none !important;
            li {
                position: relative !important;
                top: auto !important;
                left: auto !important;
                padding: 0 !important;
                width: 100% !important;
                margin-bottom: 20px !important;
            }
        }
    }
    .uk-slider-nav {
        .tm-curriculum-slider & {
            list-style: none;
            padding: 0;
            li {
                display: inline-block;
            }
        }
    }
}
@mixin hook-table-misc() {
    .uk-table {
        p {
            margin: 0;
        }
        .tm-curriculum-slider & {
            td {
                &:first-child {
                    width: 70%;
                }
                &:last-child {
                    width: 70%;
                }
            }
        }
        .uk-panel-teaser & {
            margin-bottom: 0;
        }
    }
}
@mixin hook-table() {
    border-bottom: 0;
    @media (min-width: 768px) {
        border-bottom: 1px solid $light-gray;
    }
}
@mixin hook-table-condensed() {
    font-size: 0.9em;
}
@mixin hook-description-list-misc() {
    .uk-description-list-line > dt {
        font-family: $card-heading-font;
        font-size: 1.6em;
        color: $yellow;
    }
}
@mixin hook-tab() {
    font-family: $card-heading-font;
    font-size: 1.2em;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
@mixin hook-form-misc() {
    .uk-form {
        h3 {
            font-family: $card-heading-font;
        }
        button {
            @extend .uk-button-primary;
            @extend .uk-button;
        }
        [data-freeform-controls] {
            margin-top: 15px;
        }
    }
}
@mixin hook-form() {
    width: 100%;
    border-radius: 3px;
    .tm-call-to-action & {
        background: rgba(255,255,255,0.1);
    }
    .uk-form input[type="checkbox"]:not(:disabled),
    .uk-form input[type="radio"]:not(:disabled) { background: rgba(255,255,255,0.1); }
}

@mixin hook-base-misc() {
    ul.tm-custom-bullets {
        li {
            position: relative;
            list-style: none;
            &:before {
                font-family: FontAwesome;
                content: "\f0c8";
                color: $yellow;
                font-size: 8px;
                margin-left: -16px;
                position: absolute;
                top: -1px;
            }
        }
    }
}
@mixin hook-table-misc() {
    .uk-table {
        #tm-directory & {
            th {
                font-size: $base-h6-font-size;
            }
            td, th {
                font-size: 0.9em;
                padding: 6px 6px;
                width: auto;
                @media (min-width: 768px) {
                    padding: 18px 18px;
                }
            }

        }
        th {
            font-family: $headings-font;
        }
        td {
            line-height: 1.2em;
        }
    }
}
@mixin hook-button-misc() {
    .uk-button-small {
        font-family: $body-font;
    }
    .uk-button-secondary {
        background-color: $button-secondary-background;
        color: $button-secondary-color;
        border: 1px solid $button-secondary-color;
    }

    /* Hover */
    .uk-button-secondary:hover,
    .uk-button-secondary:focus,
    .uk-button-secondary:active {
        background-color: $button-secondary-hover-background;
        color: $button-secondary-hover-color;
    }

    /* Active */
    .uk-button-secondary:active,
    .uk-button-secondary.uk-active {
        background-color: $button-secondary-active-background;
        color: $button-secondary-active-color;
    }
    .uk-button-false {
        color: $cyan;
        padding: 0;
        border: 0;
        display: inline;
    }
    /* Hover */
    .uk-button-false:hover,
    .uk-button-false:focus,
    .uk-button-false:active {
        color: darken($cyan, 5%);
    }

    /* Active */
    .uk-button-false:active,
    .uk-button-false.uk-active {
        color: darken($cyan, 5%);
    }
    .uk-button-nochevron {
        &:after {
            content: none;
        }
    }
}

@mixin hook-base-blockquote() {
    padding: 20px 20px 20px 40px;
    background: $white;
    color: darken($light-gray, 20%);
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.15);
    font-size: 1.5em;
    line-height: 1.5em;
    font-style: italic;
    border-bottom: 4px solid $yellow;
    &:before {
        content: "\201C";
        font-size: 2em;
        color: $yellow;
        margin-left: -25px;
    }
}
@mixin hook-form-stacked-label() {
    color: $gray;
    .tm-call-to-action & {
        text-align: left !important;
        color: $white;
    }
}

@mixin hook-button-primary() {
    border: 0;
}
@mixin hook-notify-message() {
    text-align: center;
}

@mixin hook-datepicker-misc() {
    .uk-datepicker {
        width: auto !important;
        box-shadow: 0px 0px 1px rgba(0,0,0,0.4);
        padding: 7px !important;
    }
    .uk-datepicker-table {
        margin-bottom: 0;
    }
}

.uk-navbar-center, .uk-navbar-left, .uk-navbar-right {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}
.uk-navbar-right {
    margin-left: auto;
}
.uk-navbar-nav > li {
    margin: 0 10px;
    @media screen and (min-width: 1220px) {
        margin: 0 12px;
        &:first-child {
            margin-left: 16px;
        }
        &:last-child {
            margin-right: 0;
        }
    }
}
.uk-navbar-nav#tertiary-nav {

    > li {
         a {
            font-family: $body-font;
            font-size: 14px;
        }
    }
}
.tm-desktop-nav-search {
    a {
        line-height: 30px;
        font-weight: 400;
        color: $white;
        &:hover, &:focus, &:active {
            color: $yellow;
        }
    }
}

.uk-accordion-title:before {
    font-family: "FontAwesome";
    content: "\f067";
    padding-right: 6px;
}
.uk-accordion-title.uk-active:before {
    font-family: "FontAwesome";
    content: "\f068";
 }
 .uk-slider-container {
    padding: 2px;
}
@mixin hook-grid-misc() {
    .uk-grid {
        /*margin-bottom: 60px;
        &:last-child {
            margin-bottom: 0;
        }*/
        
        &.uk-grid-small {
            *+.uk-grid-margin, .uk-grid+.uk-grid, .uk-grid>.uk-grid-margin {
                margin-top: 15px;
                @media (min-width: 1200px) {
                    margin-top: 30px;
                }
            }
        }
    }
    *+.uk-grid-margin, .uk-grid+.uk-grid, .uk-grid>.uk-grid-margin {
        margin-top: 30px;
        @media (min-width: 1200px) {
            margin-top: 40px;
        }
        &.uk-form-spacing {
            margin-top: 15px;
            @media (min-width: 1200px) {
                margin-top: 20px;
            }
        }
    }
}