@font-face{
    font-family:"Bebas Neue W01 Regular";
    src:url("../../../../fonts/fonts-com/3d9ec9a9-76a2-4cfe-b376-76e7340c3b50.eot?#iefix");
    src:url("../../../../fonts/fonts-com/3d9ec9a9-76a2-4cfe-b376-76e7340c3b50.eot?#iefix") format("eot"),url("../../../../fonts/fonts-com/e0d6f852-5401-4bbf-9672-47a50c5c87c6.woff2") format("woff2"),url("../../../../fonts/fonts-com/7fedd582-6ae3-4850-be2f-4acae2e74fa5.woff") format("woff"),url("../../../../fonts/fonts-com/d6e08ef3-40db-4ac3-82df-f062f55a72f5.ttf") format("truetype"),url("../../../../fonts/fonts-com/849e0009-7435-46a3-b88d-c41c3ff639d4.svg#849e0009-7435-46a3-b88d-c41c3ff639d4") format("svg");
}
@font-face{
    font-family:"Calvert MT W01 Light1488602";
    src:url("../../../../fonts/fonts-com/a576c835-3760-4caf-a352-9d2590efb60b.eot?#iefix");
    src:url("../../../../fonts/fonts-com/a576c835-3760-4caf-a352-9d2590efb60b.eot?#iefix") format("eot"),url("../../../../fonts/fonts-com/accb034c-1a9c-48f6-bf38-40ae205e80c9.woff2") format("woff2"),url("../../../../fonts/fonts-com/0e2bd249-49a7-497a-8c09-4c399eac0012.woff") format("woff"),url("../../../../fonts/fonts-com/0e65bb36-7960-4087-b811-4b5a5c238be9.ttf") format("truetype"),url("../../../../fonts/fonts-com/e8a52c47-5328-42ca-8899-5674a10d7593.svg#e8a52c47-5328-42ca-8899-5674a10d7593") format("svg");
}
@font-face{
    font-family:"Calvert MT W01 Regular";
    src:url("../../../../fonts/fonts-com/6f04cb53-3d17-4738-be7f-125b90c7081c.eot?#iefix");
    src:url("../../../../fonts/fonts-com/6f04cb53-3d17-4738-be7f-125b90c7081c.eot?#iefix") format("eot"),url("../../../../fonts/fonts-com/672b344b-3d84-4220-9478-085a087b3339.woff2") format("woff2"),url("../../../../fonts/fonts-com/11102bd0-38c8-4d60-a5f9-d422573f08dc.woff") format("woff"),url("../../../../fonts/fonts-com/77af9e05-c51d-4bef-9d6c-74430d02a2f6.ttf") format("truetype"),url("../../../../fonts/fonts-com/279dd6a9-ee9b-4e00-ba50-315c3dcf1e8c.svg#279dd6a9-ee9b-4e00-ba50-315c3dcf1e8c") format("svg");
}
@font-face{
    font-family:"Calvert MT W01 Bold1488614";
    src:url("../../../../fonts/fonts-com/6571c13d-e1f0-4876-bdff-98529f20937b.eot?#iefix");
    src:url("../../../../fonts/fonts-com/6571c13d-e1f0-4876-bdff-98529f20937b.eot?#iefix") format("eot"),url("../../../../fonts/fonts-com/98052579-0648-4e02-953f-ba7b46d13355.woff2") format("woff2"),url("../../../../fonts/fonts-com/2bc28ff1-0120-47a3-8c4e-5864479e1a55.woff") format("woff"),url("../../../../fonts/fonts-com/7d06e72d-5ebd-4e67-be44-8bffeb9a09ba.ttf") format("truetype"),url("../../../../fonts/fonts-com/6f37f93f-ae97-4aee-804f-1e140267e040.svg#6f37f93f-ae97-4aee-804f-1e140267e040") format("svg");
}