body {
    letter-spacing: 0.4px;
}
header {
    z-index: 2;
}
footer {
    position: relative;
    background: $dark-blue;
}
img {
    width: 100%;
}
a {
    transition: color .25s ease-in-out;
}