// Primary Colors
$blue: #0F3051;
$yellow: #EFB41D;

// Secondary Colors
$white: #ffffff;
$black: #000000;
$smoke: #F5F5F5;
$light-blue: #276187;
$dark-blue: #0B253F;
$light-gray: #E6E7E8;
$gray: #909090;
$gold: #F49D21;
$green: #106033;
$coral: #E74C3C;
$red: #9F1C28;
$purple: #814C91;
$teal: #1AAB8D;
$cyan: #2081BB;
$giving-green: #8cc14c;
$text-primary-color: #4A4A4A;
$nav-border: #D9D9D9;
$news-poste-date: #A5A5A5;

// Fonts
$headings-font: "Calvert MT W01 Regular";
$serif-font: "Calvert MT W01 Light1488602";
$card-heading-font: "Bebas Neue W01 Regular";
$body-font: "Open Sans", sans-serif;
$icon-font-path: "../../fonts";

$intro-font-size: 1.375em;
$intro-line-height: 1.375em;

// Base
$base-body-font-size: 14px;
$base-body-line-height: 24px;
$base-body-color: #4A4A4A;
$base-body-background: $dark-blue;
$base-body-font-family: $body-font;
$base-body-font-weight: 300;
$base-body-color: #4A4A4A;
$base-link-color: $cyan;
$base-link-hover-color: $blue;
$base-link-hover-text-decoration: none;
$base-heading-font-family: $headings-font;
$base-heading-font-weight: 600;
$base-heading-color: $blue;
$base-h1-font-size: 2.35em;
$base-h1-line-height: 1em;
$base-h2-font-size: 2.25em;
$base-h2-line-height: 1.25em;
$base-h3-font-size: 2em;
$base-h3-line-height: 1.275em;
$base-h4-font-size: 1.625em;
$base-h4-line-height: 1.5em;
$base-h5-font-size: 1.3125em;
$base-h5-line-height: 1.5em;
$base-h6-font-size: 1.125em;
$base-h6-line-height: 1.375em;
$base-em-color: inherit;
$base-blockquote-padding-left: 0;
$base-blockquote-border-width: 0;
//$base-blockquote-font-size:
//$base-blockquote-line-height:
$base-blockquote-font-style: normal;

// Block
$block-muted-background: $light-gray;

// Dropdown
$dropdown-padding: 0;
$dropdown-background: $white;
$dropdown-margin-top: 0px;

// Grid
$grid-gutter-small-vertical: 14px;

// Navbar
$navbar-background: none;
$navbar-link-hover-color: $yellow;
$navbar-nav-height: auto;
$navbar-nav-line-height: 30px;
$navbar-nav-padding-horizontal: 0;
$navbar-nav-color: $white;
$navbar-nav-font-size: 16px;
$navbar-nav-font-family: $headings-font;
$navbar-nav-hover-background: none;
$navbar-nav-hover-color: $yellow;
$navbar-nav-active-background: none;
$navbar-nav-onclick-background: none;
$navbar-nav-active-color: $yellow;
$navbar-nav-subtitle-font-size: 14px;
$navbar-toggle-font-size: 22px;
$navbar-toggle-color: $white;
$navbar-toggle-hover-color: $white;
$navbar-nav-onclick-color: darken($yellow, 5%);

// Nav
$nav-padding-vertical: 0px;
$nav-padding-horizontal: 0px;
$nav-dropdown-color: $light-gray;
$nav-dropdown-hover-background: $yellow;
$nav-nested-padding-left: 0;

// Text
$text-large-font-size: 1.375em;
$text-large-line-height: 1.5em;
$text-medium-font-size: 1.25em;
$text-medium-line-height: 1.5em;

// Accordion
$accordion-title-background: transparent;

// Button
$button-line-height: 1.125em;
$button-background: transparent;
$button-color: $white;
$button-hover-background: rgba(255, 255, 255, 0.15);
$button-hover-color: $white;
$button-active-background: transparent;
$button-active-color: $white;
$button-primary-background: $yellow;
$button-primary-color: $white;
$button-primary-hover-background: darken($yellow, 5%);
$button-primary-hover-color: $white;
$button-primary-active-background: $yellow;
$button-primary-active-color: $white;
$button-small-font-size: 14px;

$button-secondary-background: transparent;
$button-secondary-color: $gray;
$button-secondary-hover-background: $gray;
$button-secondary-hover-color: $white;
$button-secondary-active-background: $gray;
$button-secondary-active-color: $white;

$contrast-button-background: transparent;
$contrast-button-color: $white;
$contrast-button-hover-background: rgba(255, 255, 255, 0.15);
$contrast-button-hover-color: $white;
$contrast-button-active-background: transparent;
$contrast-button-active-color: $white;
$contrast-button-primary-background: $yellow;
$contrast-button-primary-color: $white;
$contrast-button-primary-hover-background: darken($yellow, 5%);
$contrast-button-primary-hover-color: $white;
$contrast-button-primary-active-background: $yellow;
$contrast-button-primary-active-color: $white;

// Form
$form-stacked-font-weight: 300 !important;
$form-gutter: 20px;
$form-height: 36px;
$form-stacked-color: $light-gray;
$form-stacked-margin-bottom: 0;
$contrast-form-placeholder-color: #999;
$form-gutter: 0;
$contrast-form-color: $white;

// Panel
$panel-box-padding: 20px;
$panel-box-background: $white;
$panel-box-color: $text-primary-color;
$panel-title-font-size: 1.875em;
$panel-title-line-height: 1em;
$panel-title-text-transform: uppercase;
$panel-title-color: $blue;

// List
$list-nested-padding-left: 0;
$list-line-margin-top: 15px;
$list-line-padding-top: 15px;
$list-striped-padding-vertical: 20px;
$list-striped-padding-horizontal: 15px;

// Subnav
$subnav-margin-vertical: 0;

// Thumbnail
$thumbnail-caption-color: darken($light-gray, 15%);

// Breadcrumbs
$breadcrumb-font-size: 13px;

// Dropdown
$dropdown-small-padding: 0;

// OffCanvas
$offcanvas-background: $blue;
$offcanvas-bar-background: $blue;
$offcanvas-bar-width: 100%;
$offcanvas-panel-color: $white;

// Tab
$tab-padding-top: 6px;
$tab-padding-horizontal: 12px;
$tab-padding-bottom: 4px;
$tab-color: $blue;
$tab-hover-color: $yellow;
$tab-active-color: $yellow;

// Text
$text-large-font-weight: normal;

// Slidenav
$slidenav-contrast-color: $light-blue;
$slidenav-contrast-hover-color: darken($light-blue, 5%);
$slidenav-width: 25px;
$slidenav-height: 35px;
$slidenav-line-height: 30px;
$slidenav-position-previous: -10px;
$slidenav-position-next: -10px;

// Utility
$utility-align-vertical: 0;
$utility-align-horizontal: 25px;

// Custom
$curriculum-border-radius: 12px;

// Dotnav
$dotnav-active-background: $yellow;
$dotnav-onclick-background: darken($yellow, 5%);
$dotnav-contrast-active-background: $yellow;
$dotnav-contrast-onclick-background: darken($yellow, 5%);
$dotnav-width: 15px;
$dotnav-height: 15px;
$dotnav-margin-horizontal: 10px;

// Datepicker
$datepicker-table-active-background: $yellow;

